// External
@import '~bootstrap/scss/bootstrap';
@import '~normalize.css/normalize.css';
@import '~react-toastify/dist/ReactToastify.css';
@import 'react-date-range/dist/styles.css'; // main css file
@import 'react-date-range/dist/theme/default.css'; // theme css file

// Config
@import 'modules/app';
@import 'modules/theme';
@import 'modules/resets';

// Layouts
@import 'modules/layout';
@import 'modules/layout.dashboard';
@import 'modules/layout.restore-password';
@import 'modules/doctor-information';

// UI Components
@import 'components/ui.header';
@import 'components/dropdown';
@import 'components/calendar';
@import 'components/order-popup';
@import 'components/prescription-popup';

// View
@import 'views/dashboard';
@import 'views/order.detail';
@import 'views/prescription.detail';
@import 'views/doctor';
