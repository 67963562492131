* {
  box-sizing: border-box;
  outline: 0;
  text-rendering: optimizeLegibility;
  text-decoration: none;
}

body {
  font-family: 'Avenir';
  margin: 0;
  padding: 0;
}

.app-inner {
  height: 100%;
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 20px;
  width: 100%;
}

.main-loading--global {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 69px);
  justify-content: center;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius:30px;
}

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:30px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
  border-radius:30px;
}

#consult-col {
  padding: 0 22px;
}