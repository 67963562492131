.prescription-detail {
  min-height: calc(100vh - #{$ui-header-height-sm});

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - #{$ui-header-height-md});
  }

  &__header a {
    color: $secondary_1;
    text-decoration: none;
  }

  &__back {
    background-color: transparent;
    border: none;
    font-family: 'Avenir Heavy';
    font-size: 18px;
    font-weight: bold;
    height: 25px;
    margin: 0;
    padding: 20px 0;

    span.icon-ic_arrow_left::before {
      color: $primary_1 !important;
      font-size: 18px;
    }
  }

  &__grid {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1;
    grid-gap: 16px;
    padding-bottom: 40px;
    width: 100%;

    @include media-breakpoint-up(md) {
      grid-auto-columns: 0.55fr 1fr;
      grid-auto-flow: column;
    }
  }

  &__grid--horizontal {
    align-items: flex-start;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    grid-gap: 16px;
    top: 0;
    width: 100%;
  }

  &__feedback {
    &--title {
      font-family: 'Avenir Heavy';
      color: $secondary_1;
      font-size: 24px;
      font-weight: bold;
    }

    &--subtitle {
      font-family: 'Avenir';
      color: $secondary_3;
      font-size: 14px;
    }
  }
}

.prescription-detail-card {
  background-color: $white;
  border: 1px solid $primary_4;
  display: flex;
  flex-direction: column;

  &__header {
    border-bottom: 1px solid $primary_4;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    @include media-breakpoint-up(md) {
      padding: 20px 30px;
    }
  }
  &__checkbox {
    padding: 0 20px;
    margin-top: 0;

    @include media-breakpoint-up(md) {
      padding: 20px 30px;
    }
  }

  &__date {
    color: $secondary_3;
    font-family: 'Avenir Heavy';
    font-size: 14;
    font-weight: bold;
    text-align: right;
  }

  &__title {
    font-family: 'Avenir Heavy';
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
  }

  &__subtitle {
    color: $primary_1;
    font-family: 'Avenir Heavy';
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    padding: 10px 20px;

    @include media-breakpoint-up(md) {
      padding: 20px 30px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    ul li .icon::before {
      color: $secondary_3;
      font-size: 16px;
    }

    ul li span.text {
      color: $secondary_3;
      font-family: 'Avenir Heavy';
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__content--overflow {
    @extend .prescription-detail-card__content;
    overflow-y: auto;
    height: calc(25vh - 75px);

    @include media-breakpoint-up(sm) {
      height: calc(30vh - 75px);
    }

    @include media-breakpoint-up(md) {
      height: calc(40vh - 75px);
    }

    @include media-breakpoint-up(lg) {
      height: calc(45vh - 75px);
    }
  }

  &__content--history {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 128px;
    padding: 0px 20px;

    @include media-breakpoint-up(md) {
      padding: 0px 30px;
    }
  }

  &__content--detail-doctor {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      padding: 20px 15px;
      border-left: 1px solid $primary_3;
    }
  }

  &__content--detail-doctor {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 20px;
    min-height: 200px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      padding: 20px 15px;
      border-left: 1px solid $primary_3;
    }
  }

  &__badge {
    align-items: center;
    background-color: $secondary_3;
    border-radius: 20px;
    color: $white;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    height: auto;
    min-height: 14px;
    padding: 3px 8px;
    text-transform: uppercase;

    &--approved {
      @extend .prescription-detail-card__badge;
      background-color: $green_1;
    }
  }

  &__grid {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 80px;
  }

  &__footer {
    @extend .prescription-detail-card__header;
    border-top: 1px solid $primary_4;
    flex-direction: row;
  }
  &__history {
    @extend .prescription-detail-card;
    min-height: 194px;
  }
}

.prescription-description {
  display: flex;
  flex-direction: column;

  &__title {
    color: $secondary_3;
    font-family: 'Avenir';
    font-size: 14px;
    font-weight: 500;
  }

  &__text {
    color: $secondary_1;
    font-family: 'Avenir Heavy';
    font-size: 14px;
    font-weight: bold;
  }
}

.prescription-btn {
  align-items: center;
  display: flex;
  font-family: 'Avenir Medium';
  font-size: 16px;
  font-weight: '700';
  height: 53px;
  justify-content: center;
  margin-right: 8px;
  width: 155px;

  .prescription-btn__label {
    margin-right: 10px;
  }

  figure {
    display: flex;
    height: 20px;
    margin: 0;
    padding: 0;
    width: 20px;
  }

  &--approve {
    @extend .prescription-btn;
    color: $secondary_4;
    background-color: $white;
    border: 1px solid $green_1;
    figure img svg {
      fill: $red_1;
    }

    &:hover {
      background-color: $green_1;
      color: $white;
      cursor: pointer;
    }
    &:disabled {
      background-color: $secondary_5;
      border-color: $secondary_4;
      cursor: not-allowed;
    }
  }

  &--reject {
    @extend .prescription-btn;
    background-color: $white;
    color: $secondary_4;
    border: 1px solid $red_1;
    &:hover {
      background-color: $red_1;
      color: $white;
      cursor: pointer;
    }
  }
}

.prescription--empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  width: 100%;

  figure {
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.prescription-overview {
  color: $secondary_1;
  font-family: 'Avenir Heavy';
  text-align: center;
  &__badge {
    padding: 4px 16px;
    border-radius: 21px;
    font-size: 12px;
    &--approved {
      @extend .prescription-overview__badge;
      background-color: $green_1;
      color: $white;
    }
    &--pending {
      @extend .prescription-overview__badge;
      background-color: $yellow_1;
      color: $secondary_1;
    }
    &--rejected {
      @extend .prescription-overview__badge;
      background-color: $red_1;
      color: $white;
    }
    &--licenses {
      @extend .prescription-overview__badge;
      background-color: $primary_1;
      color: $white;
      font-size: 10px;
    }
    &--signed {
      @extend .prescription-overview__badge;
      background-color: $secondary_1;
      color: $white;
    }
    &--gray {
      @extend .prescription-overview__badge;
      background-color: $secondary_3;
      color: $white;
      font-size: 10px;
    }
    &--gray_status {
      @extend .prescription-overview__badge;
      background-color: $secondary_3;
      color: $white;
    }
    &--gray_licenses {
      @extend .prescription-overview__badge;
      padding: 4px 8px;
      background-color: $secondary_3;
      color: $white;
    }
    &--gray_licenses_detail {
      padding: 2px 8px;
      border-radius: 21px;
      text-align: center;
      background-color: $secondary_3;
      color: $white;
      font-size: 10px;
    }
  }
  &__action {
    background: none;
    border: none;
    font-size: 5px;
    font-weight: bold;
    height: 20px;

    span:before {
      color: $white !important;
      font-size: 10px;
      height: auto;
    }
  }
}

.prescription-timeline {
  position: relative;
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 4px;
    top: 16px;
    margin: 0 0 0 10px;
    width: 2px;
    height: 70%;
    background: $primary_3;
    z-index: 2;
  }
  img {
    z-index: 3;
  }
  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $primary_3;
    margin-left: 10px;
  }
}
