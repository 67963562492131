.dashboard {
  min-height: calc(100vh - #{$ui-header-height-sm});
  padding-top: 40px;

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - #{$ui-header-height-md});
  }

  &__header {
    margin: 0px 0 40px 0;
    width: 100%;
  }

  &__overview {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__header--doctors {
    display: flex;
    flex-flow: column nowrap;
    margin: 0px 0 40px 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-flow: row nowrap;
      height: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-family: 'Avenir Heavy';
    font-size: 24px;
    font-weight: 900;
    height: 48px;
    margin: 0;
    padding: 0;
  }

  &__title--spaced {
    @extend .dashboard__title;
    margin-right: 84px;
  }
}

.prescription-overview {
  color: $secondary_1;
  font-family: 'Avenir Heavy';
  margin: 0;

  @include media-breakpoint-up(md) {
    margin-right: 50px;
  }

  &__license {
    color: $secondary_1;
    font-family: 'Avenir Heavy';
    margin-right: 50px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    height: 100%;
    gap: 10px;
  }

  &__badge {
    border-radius: 21px;
    font-size: 12px;
    padding: 4px 16px;

    &--approved {
      @extend .prescription-overview__badge;
      background-color: $green_1;
      color: $white;
    }
    &--pending {
      @extend .prescription-overview__badge;
      background-color: $yellow_1;
      color: $secondary_1;
    }
    &--rejected {
      @extend .prescription-overview__badge;
      background-color: $red_1;
      color: $white;
    }
    &--licenses {
      @extend .prescription-overview__badge;
      background-color: $primary_1;
      color: $white;
      font-size: 10px;
    }
    &--signed {
      @extend .prescription-overview__badge;
      background-color: $secondary_1;
      color: $white;
    }
    &--gray {
      @extend .prescription-overview__badge;
      background-color: $secondary_3;
      color: $white;
      font-size: 10px;
    }
    &--gray_status {
      @extend .prescription-overview__badge;
      background-color: $secondary_3;
      color: $white;
    }
    &--gray_licenses {
      @extend .prescription-overview__badge;
      color: $secondary_1;
      font-family: 'Avenir Heavy';
      margin: 0;
      background-color: $secondary_3;
      color: $white;
      font-size: 9px;
      padding: 4px 8px;
    }
  }
}

.dashboard-filters {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin-top: 20px;

    select.form-control,
    input.form-control {
      padding: 0 30px !important;
    }
  }

  &__select {
    position: relative;
    width: 100%;
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin: 0;
      width: 80%;
    }

    &--filter_status {
      @extend .dashboard-filters__select;
      &::after {
        content: ' ';
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        left: 9px;
        position: absolute;
        top: calc(50% - 11px);
        width: 20px;
      }
      &.filter_2::after {
        background-color: $green_1;
      }
      &.filter_1::after {
        background-color: $yellow_1;
      }
      &.filter_3::after {
        background-color: $red_1;
      }
      &.filter_4::after {
        background-color: $secondary_1;
      }
    }
  }

  &__search {
    margin: 0;
    position: relative;
    width: 100%;

    span.icon-ic_search,
    span.icon-ic_calendar_alt {
      background-color: $white;
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);

      &::before {
        color: $primary_1;
      }
    }
  }

  button {
    height: 46px !important;
    margin: 0;
    min-width: 149px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 150px;
    }
  }
}

.dashboard-prescription-table {
  border-top: 1px solid $primary_3;
  border-bottom: 1px solid $primary_3;
  position: relative;

  // Table Striped
  .table-striped {
    margin: 0;
    padding: 0;
  }

  .table-striped > thead {
    background-color: $primary_4;
    color: $secondary_1;
    font-family: 'Avenir Heavy';
    font-size: 16px;
    font-weight: bold;
  }

  .table-striped > tbody {
    background-color: $primary_4;
    color: $secondary_3;
    font-family: 'Avenir';
    font-size: 14px;
    &::after {
      content: ' ';
      height: calc(100% - 70px);
      width: 5px;
      display: inline-block;
      position: absolute;
      top: 65px;
      left: 0px;
    }
    &.filter_2::after {
      background-color: $green_1;
    }
    &.filter_1::after {
      background-color: $yellow_1;
    }
    &.filter_3::after {
      background-color: $red_1;
    }
    &.filter_4::after {
      background-color: $secondary_1;
    }
  }

  .table-striped tr {
    border-bottom: 0px solid transparent;
    border-left: 1px solid $primary_3;
    border-right: 1px solid $primary_3;
    border-top: 0px solid transparent;
  }

  .table-striped > thead > tr,
  .table-striped > tbody > tr {
    height: 65px !important;
    vertical-align: middle;
    &.filter_approved {
      border-left: 4px solid $green_1;
    }
    &.filter_pending {
      border-left: 4px solid $yellow_1;
    }
    &.filter_rejected {
      border-left: 4px solid $red_1;
    }
  }

  .table-striped > thead > tr > th,
  .table-striped > tbody > tr > td {
    padding: 0 20px;
  }

  .table-striped > thead > tr > th > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    width: 100%;

    & > span::before {
      color: $primary_1;
    }
  }

  .table-striped > tbody > tr > td > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .table-striped > tbody > tr:nth-child(2n + 2) > td {
    background-color: $primary_4;
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: white;
  }

  .table-striped > tbody > tr:nth-child(2n + 2) > td {
    background-color: $primary_4;
  }

  .table-striped > tbody > tr > td.actions button {
    color: $secondary_1;
    font-family: 'Avenir Heavy';
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
  }

  .table-striped
    > tbody
    > tr
    > td.actions
    button
    > span.icon-ic_review::before {
    color: $primary_1 !important;
  }

  .table-striped > tbody > tr.blocked {
    background-color: $secondary_5;
    cursor: not-allowed;

    & > * {
      color: $secondary_4;
      font-weight: bold;
    }
  }
}

.dashboard__action {
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  height: 20px;
}
